<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container>
      <v-row class="header-title-actions">
        <v-col cols="12" sm="12" md="12">
          <h1 class="page-title">{{ $tc("profile.title") }}</h1>
        </v-col>
      </v-row>

      <v-row class="cont">
        <v-col cols="12" sm="12" md="12">
          <span class="font-weight-bold">{{
            $tc("profile.general_information")
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="userInfo.displayName"
            :label="$tc('profile.username')"
            readonly
            class="mt-3"
            rounded
            filled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="userInfo.email"
            :label="$tc('profile.email')"
            readonly
            class="mt-3"
            rounded
            filled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$tc('profile.registered_since')"
            :value="createdDate"
            readonly
            class="mt-3"
            rounded
            filled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$tc('profile.role')"
            :value="userRole"
            readonly
            class="mt-3"
            rounded
            filled
          ></v-text-field>
        </v-col>
        <v-col v-if="userRole == 'Editor'" cols="12" sm="12" md="12">
          <v-btn
            @click="showAgencies"
            :height="45"
            class="mt-5"
            large
            block
            color="buttons"
          >
            {{ $tc("profile.assigned_agencies") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="cont">
        <v-col cols="12" sm="12" md="12">
          <span class="font-weight-bold">{{
            $tc("profile.user_security")
          }}</span>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-text-field
            v-model="currentPassword"
            :type="showPassword ? 'text' : 'password'"
            :label="$tc('profile.current_password')"
            :loading="updatingPassword"
            autocomplete="new-password"
            value=""
            class="mt-3"
            rounded
            filled
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-text-field
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            :label="$tc('profile.new_password')"
            :loading="updatingPassword"
            autocomplete="new-password"
            value=""
            class="mt-3"
            rounded
            filled
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-text-field
            v-model="confirmPassword"
            class="mt-3"
            :type="showPassword ? 'text' : 'password'"
            :label="$tc('profile.confirm_password')"
            :loading="updatingPassword"
            autocomplete="new-password"
            value=""
            clearable
            rounded
            filled
            v-on:keyup.enter="updatePassword"
          ></v-text-field>
        </v-col>

        <v-col
          style="cursor: pointer"
          class="mt-7 d-none d-md-block d-sm-block"
          cols="12"
          sm="1"
          md="1"
        >
          <v-icon
            style="font-size: 20px !important"
            @click="showPassword = !showPassword"
          >
            {{ showPassword ? "fas fa-eye-slash" : "fas fa-eye" }}
          </v-icon>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-btn
            :loading="updatingPassword"
            @click="updatePassword()"
            class="mt-5"
            block
            color="buttons"
            >{{ $tc("profile.update_btn") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row
        :style="
          user.MFAConfigured
            ? 'background-color: rgba(162, 198, 155, 0.35)'
            : 'background-color: rgba(187, 186, 187, 0.14)'
        "
        class="mfa"
      >
        <v-col cols="12" sm="10" md="10">
          <p
            :style="userDoc.MFAConfigured ? 'color:green' : 'color:black'"
            class="font-weight-bold mt-2"
          >
            Multi- Factor Authenication (MFA)
            {{
              userDoc.MFAConfigured
                ? $tc("profile.enabled")
                : $tc("profile.disabled")
            }}
          </p>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-btn
            @click="mfaDialog"
            block
            class="mt-1 white--text"
            :color="userDoc.MFAConfigured ? 'red darken-4' : 'buttons'"
            >{{
              userDoc.MFAConfigured
                ? $tc("profile.deactivate")
                : $tc("profile.activate")
            }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <v-dialog persistent max-width="800px" v-if="dialog" v-model="dialog">
      <mfa @cancel="dialog = false" @configured="dialog = false" />
    </v-dialog>

    <v-dialog
      max-width="40%"
      v-if="assignAgenciesDialog"
      v-model="assignAgenciesDialog"
      persistent
    >
      <assigned-agencies @cancel="assignAgenciesDialog = false" />
    </v-dialog>

    <v-dialog
      max-width="500px"
      v-if="removeFMADialog"
      v-model="removeFMADialog"
      persistent
    >
      <remove-token
        ref="tokenDelete"
        @next="confirmRemoveToken"
        @cancel="removeFMADialog = false"
      />
    </v-dialog>

    <v-dialog
      persistent
      max-width="400px"
      v-if="tokenDialog"
      v-model="tokenDialog"
    >
      <token-input
        ref="tokenComponent"
        @cancel="
          tokenDialog = false;
          updatingPassword = false;
        "
        @insertedToken="updatePassword"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TokenInput from "@/components/tokenInput.vue";
import mfa from "./MFA";
import { db, fb } from "@/firebase";
import removeToken from "@/components/removeToken";
import moment from "moment-timezone";
import "moment/locale/es";
import AssignedAgencies from "./assigned-agencies";
moment.locale("es");

export default {
  name: "Profile",
  components: {
    mfa,
    TokenInput,
    removeToken,
    AssignedAgencies,
  },
  data() {
    return {
      dialog: false,
      removeFMADialog: false,
      loading: false,
      snackbar: false,
      snackbarText: "",
      tokenDialog: false,
      password: "",
      confirmPassword: "",
      currentPassword: "",
      updatingPassword: false,
      updatingName: false,
      showPassword: false,
      userInfo: {},
      assignAgenciesDialog: false,
    };
  },

  computed: {
    ...mapState(["user", "userDoc"]),
    userRole() {
      const roles = {
        sa: "Super administrador",
        su: "Supervisor",
        ed: "Editor",
        de: "Editor de Directorios",
      };

      return this.userDoc.role ? roles[this.userDoc.role] : "";
    },
    createdDate() {
      let date = new Date(fb.auth().currentUser.metadata.creationTime);
      return moment(date).format("l");
    },
  },
  methods: {
    ...mapActions(["hide_search"]),

    mfaDialog() {
      if (!this.userDoc.MFAConfigured) {
        this.dialog = true;
      } else {
        this.removeFMADialog = true;
      }
    },
    showAgencies() {
      this.assignAgenciesDialog = true;
    },
    confirmRemoveToken(token) {
      var httpRemoveMfaToken = fb
        .functions()
        .httpsCallable("httpRemoveMfaToken");
      httpRemoveMfaToken({
        token: token,
      })
        .then((result) => {
          this.snackbarText = this.$tc("mfa.disabled");
          this.snackbar = true;
          this.removeFMADialog = false;
        })
        .catch((err) => {
          if (this.$refs.tokenDelete) this.$refs.tokenDelete.handleError();
        });
    },
    updatePassword(token) {
      if (this.validatePassword()) {
        if (token) {
          this.confirmPasswordChange(token);
        } else if (this.userDoc.MFAConfigured) {
          this.tokenDialog = true;
        } else {
          this.confirmPasswordChange();
        }
      }
    },
    confirmPasswordChange(token) {
      this.updatingPassword = true;
      var httpUpdatePassword = fb
        .functions()
        .httpsCallable("httpUpdatePassword");
      httpUpdatePassword({
        token: token ? token : "",
        currentPassword: this.currentPassword,
        password: this.password,
        confirmPassword: this.confirmPassword,
      })
        .then((result) => {
          this.updatingPassword = false;
          this.snackbarText = this.$tc("profile.password_updated");
          this.snackbar = true;
          if (this.$refs.tokenComponent)
            this.$refs.tokenComponent.handleSuccess();

          fb.auth()
            .signOut()
            .then(() => {});
        })
        .catch((err) => {
          switch (err.code) {
            case "invalid-argument":
              this.snackbarText = this.$t("profile.password_incorrect");
              this.snackbar = true;
              if (this.$refs.tokenComponent) this.$refs.tokenComponent.cancel();
              break;

            case "weak-password":
              this.snackbarText = this.$t("profile.min_6");
              this.snackbar = true;
              if (this.$refs.tokenComponent) this.$refs.tokenComponent.cancel();
              break;

            case "internal":
              if (this.$refs.tokenComponent)
                this.$refs.tokenComponent.tokenError();
              break;

            default:
              this.snackbarText = this.$tc("messages.unknown");
              this.snackbar = true;
              if (this.$refs.tokenComponent) this.$refs.tokenComponent.cancel();
              break;
          }

          this.updatingPassword = false;
        });
    },
    updateName() {
      this.updatingName = true;
      var httpUpdateName = fb.functions().httpsCallable("httpUpdateName");
      httpUpdateName({
        name: this.userInfo.displayName,
      })
        .then((result) => {
          this.updatingName = false;
          this.snackbarText = this.$t("profile.name_updated_successfully");
          this.snackbar = true;
        })
        .catch((err) => {
          this.updatingName = false;
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
        });
    },
    validatePassword() {
      if (!this.currentPassword || !this.password || !this.confirmPassword) {
        this.snackbarText = this.$t("profile.enter_new_password");
        this.snackbar = true;
        return false;
      } else if (this.password != this.confirmPassword) {
        this.snackbarText = this.$t("profile.no_match");
        this.snackbar = true;
        return false;
      } else if (this.password.length < 6) {
        this.snackbarText = this.$t("profile.min_6");
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },
  },
  beforeDestroy() {
    this.hide_search(false);
  },
  mounted() {
    this.userInfo = Object.assign({}, this.user);
    this.hide_search(true);
  },
};
</script>

<style lang="scss" scoped>
@import "../../responsive";

.mfa {
  border-radius: 10px;
  margin: 15px;
}

.cont {
  background-color: rgba(187, 186, 187, 0.14);
  border-radius: 10px;
  margin: 20px;

  @include responsive(mobile) {
  }
}
</style>