<template>
  <div>
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span v-if="!loading" class="headline">{{
          $tc("assigned_agencies.title")
        }}</span>
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="min-height: 300px">
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <search
                :loading="loading"
                :name="$tc('assigned_agencies.search')"
              />
            </v-col>
            <v-col v-if="loading" cols="12" sm="12" md="12">
              <v-skeleton-loader
                v-for="(item, i) in 3"
                :key="i"
                type="list-item-two-line"
              ></v-skeleton-loader>
            </v-col>
            <v-col v-else cols="12" sm="12" md="12">
              <div
                v-if="!filteredItems.length"
                style="width: 100%; justify-content: center; display: flex"
              >
                {{ $tc("assigned_agencies.empty") }} 😅
              </div>
              <v-list
                v-else
                class="ml-6"
                width="95%"
                style="background-color: #fff !important; min-height: 200px"
              >
                <v-list-item
                  v-for="(item, i) in filteredItems"
                  :key="i"
                  two-line
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.description
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "assigned-agencies",
  data() {
    return {
      loading: true,
      agencies: [],
    };
  },
  computed: {
    ...mapState(["search", "userDoc"]),
    filteredItems() {
      if (this.search) {
        return this.agencies.filter(
          (item) =>
            item.name.toLowerCase().includes(this.search.toLowerCase()) ||
            item.description.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return this.agencies;
      }
    },
  },

  async mounted() {
    let agencies =
      this.userDoc.agencies && this.userDoc.agencies.length
        ? this.userDoc.agencies
        : [];

    for (let i = 0; i < agencies.length; i = i + 10) {
      let tempArray = agencies.slice(i, i + 10);
      await db
        .collection(`agencies`)
        .where(fb.firestore.FieldPath.documentId(), "in", tempArray)
        .get()
        .then((response) => {
          response.forEach((item) => {
            let agency = item.data();
            agency.id = item.id;
            this.agencies.push(agency);
          });
        });
    }
    this.loading = false;
  },
};
</script>