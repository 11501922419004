<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">{{$tc('mfa.remove_title')}}</span>
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <p>
               {{$tc('mfa.remove_message')}}
              </p>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="token"
                label="Token"
                v-on:keyup.enter="onNext"
                @keypress="maxLength($event, token)"
                class="mt-3"
                name="input-10-1"
                rounded
                filled
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" class="mb-4 mx-2">
          <v-btn
            :loading="loading"
            class="flex-grow-1 flex-shrink-0 white--text"
            large
            @click="onNext"
            rounded
            :elevation="0"
            :minHeight="56"
            block
            color="red darken-4"
          >
            {{$tc('button.accept')}}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "remove-token",
  data() {
    return {
      loading: false,
      snackbarText: "",
      snackbar: false,
      token: "",
    };
  },
  methods: {
    onNext() {
      if (this.token && this.token.length == 6) {
        this.loading = true;
        this.$emit("next", this.token);
      } else {
        this.snackbarText = this.$tc('messages.min_token');
        this.snackbar = true;
      }
    },
    handleSuccess() {
      this.loading = false;
      this.$emit("complete");
    },
    handleError() {
      this.snackbarText = this.$tc('messages.invalid_token');
      this.snackbar = true;
      this.loading = false;
    },
    maxLength(evt, field) {
      var keycode = event.which;
      if (
        field.length < 6 &&
        evt.shiftKey == false &&
        (keycode == 46 ||
          keycode == 8 ||
          keycode == 37 ||
          keycode == 39 ||
          (keycode >= 48 && keycode <= 57))
      )
        return true;
      else evt.preventDefault();
    },
  },
};
</script>

<style scoped>
p {
  font-size: 18px !important;
}
</style>