<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card :loading="loading" class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">Multi- Factor Authenication</span>
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-img
                lazy-src="https://firebasestorage.googleapis.com/v0/b/beanage-data/o/qr-loading.gif?alt=media&token=b717be59-eb5d-4702-a279-ac98000369e2"
                style="width: 100%"
                :src="url"
              >
              </v-img>
            </v-col>
            <v-col cols="12" sm="8" md="8">
              <span class="font-weight-bold">{{$tc('mfa.title')}}</span>
              <hr class="title-hr mb-8" />
              <p>1. {{$tc('mfa.step_one')}}</p>
              <p>
                2. {{$tc('mfa.step_two')}}
              </p>
              <p>
                3. {{$tc('mfa.step_three')}}
              </p>
              <v-text-field
                label="Código de Google Authenticator"
                :loading="loading"
                v-model="token"
                @keypress="maxLength($event, token)"
                v-on:keyup.enter="verifyToken"
                clearable
                autofocus
                rounded
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn :loading="loading" @click="verifyToken" color="buttons"
                  >{{$tc('dialog.save')}}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import QRCode from "qrcode";

export default {
  name: "mfa",
  data() {
    return {
      loading: true,
      snackbar: false,
      snackbarText: "",
      url: "",
      token: "",
    };
  },
  computed: {
    ...mapState(["user"]),
  },

  methods: {
    verifyToken() {
      if (this.token && this.token.length == 6) {
        this.loading = true;
        var httpVerifyMfaToken = fb
          .functions()
          .httpsCallable("httpVerifyMfaToken");
        httpVerifyMfaToken({ token: this.token })
          .then((result) => {
            this.loading = false;
            this.snackbarText =
              this.$tc('mfa.successfully');
            this.snackbar = true;
            this.$emit("configured");
          })
          .catch((err) => {
            this.snackbarText = this.$tc('messages.invalid_token');
            this.snackbar = true;
            this.loading = false;
          });
      } else {
        this.snackbarText = this.$tc('messages.min_token');
        this.snackbar = true;
      }
    },
    maxLength(evt, field) {
      var keycode = event.which;
      if (
        field.length < 6 &&
        evt.shiftKey == false &&
        (keycode == 46 ||
          keycode == 8 ||
          keycode == 37 ||
          keycode == 39 ||
          (keycode >= 48 && keycode <= 57))
      )
        return true;
      else evt.preventDefault();
    },
  },

  mounted() {
    var httpGenerateMfaToken = fb
      .functions()
      .httpsCallable("httpGenerateMfaToken");
    httpGenerateMfaToken()
      .then((result) => {
        if (result.data.url) {
          QRCode.toDataURL(result.data.url, (err, url) => {
            this.url = url;
            this.loading = false;
          });
        } else {
          this.snackbarText = this.$tc('mfa.user_configured');
          this.snackbar = true;
          this.$emit("configured");
        }
      })
      .catch((err) => {
        this.snackbarText =
          this.$tc('messages.unknown');
        this.snackbar = true;
        this.$emit("cancel");
      });
  },
};
</script>